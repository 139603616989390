import { FC } from "react";
import { HiArrowDown } from '@react-icons/all-files/hi/HiArrowDown';

const DownArrowIcon: FC = () => {

    return (
        <HiArrowDown />
    )
}

export default DownArrowIcon;
