import React, { useEffect } from "react";
import { FC } from "react";
import { ResourceSummary } from "../../models/resourceSummary";
import { CampaignTheme } from "../../models/campaignTheme";
import FileDialog from "../fileDialog";
import { PageElement } from "../../models/pageElement";
import { Box, Button, Center, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from "@chakra-ui/react";
import PlayIcon from "../icons/playIcon";
import PauseIcon from "../icons/pauseIcon";
import { ContentPageContext } from "../contentPage";

interface AudioProps {
  editMode: boolean;
  state?: PageElement;
  theme?: CampaignTheme;
  onUpdate?: (updatedState: PageElement) => void;
  updateContentPageContext?: (contentPageContext: ContentPageContext) => void;
}

const Audio: FC<AudioProps> = ({ editMode, state, onUpdate, updateContentPageContext }) => {

  const buttonIconSize = 40;
  const heightPx = 55;


  const player = React.useRef<HTMLAudioElement>(null);

  const [fullAudioUrl, setFullAudioUrl] = React.useState<string>(state?.resources?.[0].url ?? "");
  const [progress, setProgress] = React.useState<number>(0);

  const updateState = (resourceSummary: ResourceSummary) => {
    const resourceUrl = resourceSummary.url ?? '';
    setFullAudioUrl(resourceUrl);
    onUpdate?.({ type: "audio", resources: [{ url: resourceUrl }] });
  }

  const togglePlay = () => {
    player?.current?.paused ? player?.current?.play() : player?.current?.pause()
  }

  const updateFromTimeBar = (progressValue: number) => {
    setProgress(progressValue);

    if (player?.current) {
      player.current.currentTime = (progressValue / 100) * player.current.duration;
    }
  }

  /**
   * Formats seconds into a string with the format minute:seconds. Empty if the number is NaN or infinite.
   */
  const secondsToDuration = (seconds?: number) => {
    let duration = '';
    if ((seconds || seconds === 0) && Number.isFinite(seconds)) {
      const minutes = Math.floor(seconds / 60);
      const remSeconds = Math.floor(seconds % 60);
      duration = `${minutes}:${remSeconds.toString().padStart(2, "0")}`;
    }
    return duration;
  }

  const isPlaying = () => {
    if (!player.current) {
      return false;
    }
    return player.current.paused === false;
  };

  useEffect(() => {
    player?.current?.addEventListener("timeupdate", function () {
      if (player.current) {
        const _progress = (player.current.currentTime / player.current.duration) * 100.0;
        setProgress(_progress);
      }
    });
  }, []);

  if (!editMode) {
    updateContentPageContext?.({ bottomPaddingPx: heightPx });
  }

  return (
    <>
      {editMode ?
        <>
          <FileDialog onSubmit={updateState} />
          {fullAudioUrl}
          <audio controls src={fullAudioUrl} />
        </>
        :
        <>
          <audio preload="auto" ref={player} src={fullAudioUrl} />
          <Box padding='2' w="100vw" h={`${heightPx}px`} position='fixed' left='0px' right='0px' bottom='0px' backgroundColor='black' color={'white'}>
            <Center>
              <Box w="4xl">
                <HStack>
                  <Button
                    onClick={togglePlay}
                    leftIcon={isPlaying() ? <PauseIcon size={buttonIconSize} /> : <PlayIcon size={buttonIconSize} />}
                    colorScheme="blackAlpha" />
                  <Text paddingRight='1'>{secondsToDuration(player.current?.currentTime)}</Text>
                  <Slider aria-label='slider-ex-1' value={progress} onChange={updateFromTimeBar}>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                  <Text paddingLeft='1'>{secondsToDuration(player.current?.duration)}</Text>
                </HStack>
              </Box>
            </Center>
          </Box>
        </>
      }
    </>
  );
};

export default Audio;
