import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { AiFillVideoCamera } from "react-icons/ai";

const VideoIcon: FC<IconBaseProps> = (props) => {

    return (
        <AiFillVideoCamera {...props} />
    )
}

export default VideoIcon;
