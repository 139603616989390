import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import { DeleteIcon } from "@chakra-ui/icons";

interface NewComponentPromptProps {
    onDelete?: () => void;
}

const NewComponentPrompt: FC<NewComponentPromptProps> = ({ onDelete }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const deleteComponent = () => {
        onDelete?.();
        onClose();
    }

    return (<>
        <Button colorScheme='red' rightIcon={<DeleteIcon />} onClick={onOpen} >Delete</Button>

        <Modal isOpen={isOpen} onClose={onClose} size='2xl' >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Component?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Delete component? This action cannot be reversed.
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' onClick={deleteComponent} mr={3}>Delete</Button>
                    <Button variant='ghost' onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
    );
};

export default NewComponentPrompt;