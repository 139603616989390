import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { AiFillFolderOpen } from "react-icons/ai";

const DirectoryIcon: FC<IconBaseProps> = (props) => {

    return (
        <AiFillFolderOpen height='100%' {...props} />
    )
}

export default DirectoryIcon;
