import { useCallback, useState } from "react";
import store from "store";

// Hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  console.log("Getting from local storage...");
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      console.warn("Early return from local storage");
      return initialValue;
    }

    try {
      console.log("Getting");
      // Get from local storage by key
      const item = store.get(key);
      console.log("got");
      console.log(item);
      // Parse stored json or if none return initialValue
      return item ? item : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    console.log("Setting value to local storage.");
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      console.debug("Saved to react store.")
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        console.debug("Saving to local storage");
        store.set(key, valueToStore);
        console.debug("Saving to local storage complete.");
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [
    storedValue,
    useCallback(setValue, [key, storedValue, setStoredValue]),
  ] as const;
}