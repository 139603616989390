import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { BiPlayCircle } from "react-icons/bi";

const PlayIcon: FC<IconBaseProps> = (props) => {

    return (
        <BiPlayCircle {...props} />
    )
}

export default PlayIcon;
