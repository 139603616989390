import { useMsal } from '@azure/msal-react';
import { Box, Button, Heading, Text, Textarea, useToast } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { loginRequest } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { Constants } from '../constants';

const LoginPage: FC = () => {

    console.log("login page...");

    const { instance } = useMsal();
    const toast = useToast();

    const [jwtInfoState, setJwtInfoState] = useState<string>("");

    const { jsonAuthFetch } = useFetchWithMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    const fetchJwtInfo = useCallback(() => {
        console.log("fetching jwt info");
        jsonAuthFetch(`${Constants.url}/jwt-info`)
            .then(response => {
                if (response) {
                    console.log("response:");
                    console.log(response);
                    setJwtInfoState(JSON.stringify(response, null, 2));
                }
                else {
                    setJwtInfoState("=== empty response ===");
                }
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching jwt info.',
                    description: `Reason: ${reason}`
                });
            });
    }, [jsonAuthFetch, toast]);

    useEffect(() => {
        fetchJwtInfo();
    }, [fetchJwtInfo]);

    console.log("login page rendering...");

    return (
        <Box width="100%" padding={2}>

            <Heading>Login xxxyyy</Heading>
            <Text>This is an app that exists.</Text>

            <Button onClick={() => handleLogin()} >Sign in using Redirect</Button>
            <Button onClick={() => { fetchJwtInfo() }} >Refresh</Button>

            <Textarea h='lg' fontFamily={'monospace'} value={jwtInfoState} />
        </Box>
    );
};

export default LoginPage;
