import { Text, Button, Skeleton, useToast, HStack } from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { Constants } from "../constants";
import { UserInfo } from "../models/userInfo";
import { setIsAuthenticated, setUsername } from "../features/storeSlice";
import { useAppDispatch } from "../hooks";
import { useMsal } from "@azure/msal-react";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import { loginRequest } from "../authConfig";

const Login: FC = () => {
    const { instance } = useMsal();
    const toast = useToast();
    const dispatch = useAppDispatch();
    const [userInfoState, setUserInfoState] = React.useState<UserInfo | null>(null);
    const [isLoadedState, setIsLoadedState] = React.useState<boolean>(false);

    const { jsonAuthFetch } = useFetchWithMsal();

    useEffect(() => {
        const fetchUserInfo = () => {
            jsonAuthFetch(`${Constants.url}/user-info`)
                .then(data => {
                    setUserInfoState(data);
                    setIsLoadedState(true);
                    dispatch(setIsAuthenticated(data.isAuthenticated));
                    dispatch(setUsername(data.name));
                })
                .catch(reason => {
                    console.error(reason);
                    toast({
                        status: 'error',
                        title: 'Error fetching user info.',
                        description: `Reason: ${reason}`
                    });
                });
        };
        fetchUserInfo();
    }, [instance, jsonAuthFetch, toast, dispatch]);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <>
            <Skeleton isLoaded={isLoadedState}>
                {isLoadedState ?
                    <>
                        {userInfoState?.isAuthenticated ?
                            <HStack>
                                <Text fontSize='xs' fontWeight='semibold' color='gray.500' marginRight={3}>
                                    {userInfoState?.name}
                                </Text>
                                <Button size='xs' pointerEvents='auto' variant='link' onClick={_ => handleLogout()}>
                                    Logout
                                </Button>
                            </HStack>
                            :
                            <Button size='xs' pointerEvents='auto' variant='link' onClick={_ => handleLogin()}>
                                Login
                            </Button>
                        }
                    </>
                    :
                    <>Fake user name</>
                }
            </Skeleton>
        </>
    )
}

export default Login;
