import { FC } from "react";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";

const SettingsIcon: FC = () => {

    return (
        <HiOutlineWrenchScrewdriver />
    )
}

export default SettingsIcon;
