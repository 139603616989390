import { Box, Button, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { useRef, FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocalStorage } from '../localStorage';
import { useLocation } from 'react-router-dom';

class PrivacyBannerChoice {
    allowAllCookies = false;
    choiceMade = false;
}

const PrivacyBanner: FC = () => {
    const allowButtonRef = useRef<HTMLButtonElement>(null);

    const [privacyBannerChoice, setPrivacyBannerChoice] = useLocalStorage<PrivacyBannerChoice>("privacy-banner-choice", new PrivacyBannerChoice());

    ReactGA.initialize("G-QGK5ZFTWS1", {
        gtagOptions: {
            send_page_view: false
        }
    });

    const location = useLocation();

    const choiceButtonPressed = (allowAllCookies: boolean) => {
        const privacyBannerChoice = { allowAllCookies: allowAllCookies, choiceMade: true };
        setPrivacyBannerChoice(privacyBannerChoice);
        console.debug("Privacy banner choice made:");
        console.debug(privacyBannerChoice);
    };

    useEffect(() => {
        console.debug(`Page changed: ${location.pathname}`);
        if (privacyBannerChoice.allowAllCookies) {
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
            console.debug("Submitted GA event.");
        }
    }, [location, privacyBannerChoice]);

    return (
        privacyBannerChoice.choiceMade ? <></> :
            <Box
                w='100vw'
                position='fixed'
                left='0px'
                right='0px'
                bottom='0px'
                backgroundColor={'white'}
                padding={4}
                zIndex={200}
                boxShadow={'2px 2px 14px 2px black'}
            >
                <Center>
                    <Box maxW={'2xl'}>
                        <Heading size='md'>This website uses cookies</Heading>

                        <Text>We use cookies for website functionality and traffic analysis.</Text>
                        <Text mb={4}>Information is shared with analytics partners. We do not collect or store any personal data.</Text>

                        <Flex justify='right'>
                            <Button variant='outline' mr={4} onClick={() => choiceButtonPressed(false)}>
                                Necessary cookies only
                            </Button>
                            <Button colorScheme='blue' ref={allowButtonRef} onClick={() => choiceButtonPressed(true)}>
                                Allow all cookies
                            </Button>
                        </Flex>
                    </Box>
                </Center>
            </Box>
    )
}

export default PrivacyBanner;
