import { FC } from "react";
import { CampaignTheme } from "../../models/campaignTheme";
import PageText from "./pageText";
import Picture from "./picture";
import Audio from "./audio";
import Subtitle from "./subtitle";
import Title from "./title";
import Youtube from "./youtube";
import ButtonOptions from "./buttonOptions";
import { PageElement } from "../../models/pageElement";
import { ContentPageContext } from "../contentPage";
import Video from "./video";

interface PartSelectorProps {
    editMode: boolean;
    pageElement: PageElement;
    pageElementIndex: number;
    theme: CampaignTheme;
    pageId: string;
    campaignId: string;
    updatePageElement: (pageElementState: PageElement, pageElementIndex: number) => void;
    updateContentPageContext: (contentPageContext: ContentPageContext) => void;
}

const PartSelector: FC<PartSelectorProps> = ({ editMode, pageElement, pageElementIndex, theme, pageId, campaignId, updatePageElement, updateContentPageContext }) => {

    const partType = pageElement?.type ?? '';

    return (<>
        {partType === "title" ?
            <Title editMode={editMode} theme={theme} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "subtitle" ?
            <Subtitle editMode={editMode} theme={theme} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "image" ?
            <Picture editMode={editMode} theme={theme} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "audio" ?
            <Audio editMode={editMode} theme={theme} state={pageElement} updateContentPageContext={updateContentPageContext} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "video" ?
            <Video editMode={editMode} theme={theme} state={pageElement} updateContentPageContext={updateContentPageContext} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "text" ?
            <PageText editMode={editMode} theme={theme} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "button-options" ?
            <ButtonOptions editMode={editMode} theme={theme} pageId={pageId} campaignId={campaignId} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} /> : <></>}
        {partType === "youtube" ?
            <Youtube editMode={editMode} theme={theme} state={pageElement} onUpdate={(state) => updatePageElement(state, pageElementIndex)} />
            : <></>}
    </>);
};

export default PartSelector;