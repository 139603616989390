import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Image, Heading, Text, Box, Textarea } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { ResourceSummary } from "../../models/resourceSummary";
import { CampaignTheme } from "../../models/campaignTheme";
import FileDialog from "../fileDialog";
import { PageElement, PageElementResource } from "../../models/pageElement";

interface PictureProps {
  editMode: boolean;
  state?: PageElement;
  theme?: CampaignTheme;
  onUpdate?: (updatedState: PageElement) => void;
}

const Picture: FC<PictureProps> = ({ editMode, state, onUpdate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fullImageUrl, setFullImageUrl] = React.useState<PageElementResource[]>(state?.resources ?? []);

  const updateUrl = (resourceSummary: ResourceSummary) => {
    const resourceUrl = resourceSummary?.url ?? '';
    let pageElementResource = fullImageUrl?.[0] ?? {};
    pageElementResource.url = resourceUrl;
    setFullImageUrl([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  const updateAltText = (altText: string) => {
    let pageElementResource = fullImageUrl?.[0] ?? {};
    pageElementResource.altText = altText;
    setFullImageUrl([pageElementResource]);
    onUpdate?.({ type: "image", resources: [pageElementResource] });
  }

  return (
    <>
      <Image boxSize="400px" fit={'scale-down'} src={fullImageUrl?.[0]?.url} alt={fullImageUrl?.[0]?.altText} onClick={onOpen} />

      <Modal onClose={onClose} size='6xl' isOpen={isOpen}>
        <ModalOverlay bg='none'
          backdropFilter='auto'
          backdropInvert='80%'
          backdropBlur='2px' />
        <ModalContent>
          <ModalHeader>Close Up</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image src={fullImageUrl?.[0]?.url} />
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {editMode ?
        <>
          <FileDialog onSubmit={updateUrl} />
          <Box textAlign={"left"}>
            <Heading size='sm'>Alt Text</Heading>
            <Text>
              Alternative (Alt) Text is meant to convey the "why" of the image as it relates to the content.
              It is read aloud to users by screen reader software, and  displays on the page if the image fails to load.
            </Text>
            <Text>
              Alt text can be several sentences long.
            </Text>
          </Box>
          <Textarea
            value={fullImageUrl?.[0]?.altText ?? ''}
            onChange={e => { updateAltText(e.target.value); }}
            placeholder='Image alt text'
            size='sm'
          />
        </>
        :
        <></>
      }
    </>
  );
};

export default Picture;