import { Button, Card, CardBody, CardFooter, CardHeader, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import { AddIcon } from "@chakra-ui/icons";

interface NewComponentPromptProps {
    editMode: boolean;
    onUpdate?: (newPartState: any) => void;
}

const NewComponentPrompt: FC<NewComponentPromptProps> = ({ editMode, onUpdate }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const createComponent = (componentKey: string) => {
        onUpdate?.({ type: componentKey });
        onClose();
    }

    const componentTypes = [
        { key: "title", name: "Title", description: "A title element. Used for titling a page and labelling different parts of content." },
        { key: "subtitle", name: "Subtitle", description: "Subtitle for a page." },
        { key: "image", name: "Image", description: "A static image. Administrators can upload images for uses to see. Images can be enlarged by pressing them." },
        { key: "audio", name: "Audio", description: "An audio track. Administrators can upload sound for users to listen to. The page will have a bar at the bottom of the screen for play, pause, and seek. A page should only have one track." },
        { key: "video", name: "Video", description: "A video player. Administrators can upload for users to watch." },
        { key: "text", name: "Text", description: "Words for the page." },
        { key: "button-options", name: "Button options", description: "A static list of buttons that enable navigation to other pages. Any number of buttons is supported." },
        { key: "youtube", name: "Youtube", description: "An embedded Youtube video." }
    ];

    return (<>
        {editMode ?
            <>
                <Button colorScheme='blue' rightIcon={<AddIcon />} onClick={onOpen}>New Component</Button>

                <Modal isOpen={isOpen} onClose={onClose} size='2xl' >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Component</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {componentTypes.map((ct) => {
                                return <Card key={ct.key} marginBottom='8' >
                                    <CardHeader>
                                        <Heading size='md'>{ct.name}</Heading>
                                    </CardHeader>
                                    <CardBody>
                                        <Text>{ct.description}</Text>
                                    </CardBody>
                                    <CardFooter>
                                        <Button colorScheme='blue' width={'12em'} onClick={_e => createComponent(ct.key)} >
                                            Create {ct.name}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            })}
                        </ModalBody>

                        <ModalFooter>

                            <Button variant='ghost' onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
            :
            <></>
        }
    </>);
};

export default NewComponentPrompt;