import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { AiFillSound } from "react-icons/ai";

const AudioIcon: FC<IconBaseProps> = (props) => {

    return (
        <AiFillSound {...props} />
    )
}

export default AudioIcon;
