import { createSlice } from '@reduxjs/toolkit'

export const storeSlice = createSlice({
  name: 'store',
  initialState: {
    username: '',
    isAuthenticated : false
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUsername, setIsAuthenticated } = storeSlice.actions

export default storeSlice.reducer;
