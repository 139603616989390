import { Text, InputGroup, InputLeftAddon, Input } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { CampaignTheme } from "../../models/campaignTheme";
import { PageElement } from "../../models/pageElement";

interface SubtitleProps {
    editMode: boolean;
    state?: PageElement;
    theme?: CampaignTheme;
    onUpdate?: (updatedState: PageElement) => void;
}

const Subtitle: FC<SubtitleProps> = ({ editMode, state, onUpdate }) => {

    const [subtitleText, setSubtitleText] = React.useState<string>(state?.text ?? "");

    const updateState = (subtitle: string) => {
        setSubtitleText(subtitle);
        onUpdate?.({ type: "subtitle", text: subtitle });
    }

    return (<>
        {editMode ?
            <InputGroup>
                <InputLeftAddon children='Subtitle' />
                <Input aria-label="subtitle" value={subtitleText} onChange={e => { updateState(e.target.value); }} />
            </InputGroup>
            :
            <Text>{subtitleText}</Text>
        }
    </>);
};

export default Subtitle;