import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';

const About: FC = () => {

    return (
        <Box width="100%" padding={2}>

            <Heading>About</Heading>
            <Text>This is an app that exists.</Text>
        </Box>
    );
};

export default About;
