import { AddIcon } from "@chakra-ui/icons";
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, FormControl, FormHelperText, FormLabel, Box, useToast } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "../constants";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

interface CreateContentPageProps {
    campaignId: string
}

const CreateContentPage: FC<CreateContentPageProps> = ({ campaignId }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [nameState, setNameState] = React.useState<string>("");
    const navigate = useNavigate();
    const toast = useToast();

    const { jsonAuthFetch } = useFetchWithMsal();

    const createPage = () => {
        jsonAuthFetch(`${Constants.url}/create-page-content`, { id: nameState, campaignId: campaignId })
            .then(data => {
                navigate(`/page/${data.campaignId}/${data.id}/edit`);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching page data.',
                    description: `Reason: ${reason}`
                });
            });
    };

    return (
        <Box>
            <Button colorScheme='blue' rightIcon={<AddIcon />} onClick={onOpen}>Add Page</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Page</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={nameState} onChange={e => setNameState(e.target.value)} onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    createPage();
                                }
                            }} />
                            <FormHelperText>Name of the page. This will be used to in the page's web address. <b>It must be unique and cannot be changed later.</b></FormHelperText>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={_e => createPage()} >
                            Create
                        </Button>
                        <Button variant='ghost' onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default CreateContentPage;
