import { ColorResult } from "react-color";

/**
 * Converts RGBA into a hex stream. RGB are numbers between 0 and 255, the a component must be a number between 0.0 and 1.0.
 * @param r 
 * @param g 
 * @param b 
 * @param a 
 * @returns 
 */
export function rgbaToHex(r: number, g: number, b: number, a: number): string {

    let roundR = Math.round(Math.min(255, Math.max(r, 0)));
    let roundG = Math.round(Math.min(255, Math.max(g, 0)));
    let roundB = Math.round(Math.min(255, Math.max(b, 0)));


    let roundA = Math.round(Math.min(255, Math.max(a * 255, 0)));

    return `#${roundR.toString(16)}${roundG.toString(16)}${roundB.toString(16)}${roundA.toString(16)}`;
}

export function colourResultToHex(colourResult: ColorResult) {
    return rgbaToHex(colourResult.rgb.r, colourResult.rgb.g, colourResult.rgb.b, colourResult.rgb.a!);
}