import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { BiPauseCircle } from "react-icons/bi";

const PauseIcon: FC<IconBaseProps> = (props) => {

    return (
        <BiPauseCircle {...props} />
    )
}

export default PauseIcon;
