import { FC } from "react";
import { IconBaseProps } from "react-icons";
import { AiFillFileImage } from "react-icons/ai";

const ImageIcon: FC<IconBaseProps> = (props) => {

    return (
        <AiFillFileImage {...props} />
    )
}

export default ImageIcon;
