import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Text, Heading, Input, LinkBox, LinkOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, Table, TableContainer, Tbody, Td, Textarea, Th, Thead, Tooltip, Tr, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useCallback } from "react";
import { FC } from "react";
import DownArrowIcon from "../icons/downArrow";
import UpArrowIcon from "../icons/upArrow";
import { Constants } from "../../constants";
import { PageContentSummary } from "../../models/pageContentSummary";
import { ButtonOption } from "../../models/buttonOption";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../icons/settingsIcons";
import { CampaignTheme } from "../../models/campaignTheme";
import { PageElement } from "../../models/pageElement";
import useFetchWithMsal from "../../hooks/useFetchWithMsal";

interface ButtonOptionsProps {
    editMode: boolean;
    pageId?: string;
    campaignId?: string;
    state?: PageElement;
    theme?: CampaignTheme;
    onUpdate?: (updatedState: PageElement) => void;
}

const ButtonOptions: FC<ButtonOptionsProps> = ({ editMode, pageId, campaignId, state, theme, onUpdate }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [optionsState, setOptionsState] = React.useState<ButtonOption[]>(state?.options ?? []);
    const [isPageSummaryLoadedState, setIsPageSummaryLoadedState] = React.useState<boolean>(false);
    const [pageSummaries, setPageSummaries] = React.useState<PageContentSummary[] | null>(null);
    const [optionIndex, setOptionIndex] = React.useState<number>(0);
    const [isButtonSpinning, setIsButtonSpinning] = React.useState<boolean>(false);

    const { jsonFetch } = useFetchWithMsal();

    const fetchCampaignData = useCallback((campaignId: string) => {
        if (!editMode) {
            return;
        }
        jsonFetch(`${Constants.url}/content-pages/campaign/${campaignId}`)
            .then(data => {
                setPageSummaries(data);
                setIsPageSummaryLoadedState(true);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching campaign data.',
                    description: `Reason: ${reason}`
                });
            });
    }, [jsonFetch, toast, editMode]);

    const executeOption = (pageId: string, optionId: string) => {
        setIsButtonSpinning(true);
        jsonFetch(`${Constants.url}/execute/${campaignId}/${pageId}/option/${optionId}`)
            .then(data => {
                navigate(`/page/${campaignId}/${data.pageId}`);
            })
            .catch(reason => {
                console.error(reason);
                toast({
                    status: 'error',
                    title: 'Error fetching option data.',
                    description: `Reason: ${reason}`
                });
            })
            .finally(() => {
                setIsButtonSpinning(false);
            });
    };

    const updateState = (buttonOptions: ButtonOption[]) => {
        setOptionsState(buttonOptions);
        onUpdate?.({ type: "button-options", options: buttonOptions });
    }


    useEffect(() => {
        setIsPageSummaryLoadedState(false);
        if (campaignId) {
            fetchCampaignData(campaignId);
        }
    }, [campaignId, fetchCampaignData]);

    return (<>
        {editMode ?
            <Box>
                <VStack hidden={isPageSummaryLoadedState}>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </VStack>
                <TableContainer hidden={!isPageSummaryLoadedState}>
                    <Table size='sm'>
                        <Thead>
                            <Tr>
                                <Th>Order</Th>
                                <Th>Option Text</Th>
                                <Th>Page</Th>
                                <Th>View</Th>
                                <Th>Advanced</Th>
                                <Th>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {optionsState?.map((opt, i) => {
                                return <Tr key={i}>
                                    <Td>
                                        <Button isDisabled={i === 0} variant={'ghost'} leftIcon={<UpArrowIcon />} onClick={() => {
                                            let optionClone = [...optionsState!];
                                            const row = optionClone.splice(i, 1)[0];
                                            optionClone.splice(i - 1, 0, row);
                                            updateState(optionClone);
                                        }} ></Button>
                                        <Button isDisabled={i === (optionsState?.length ?? 0) - 1} variant={'ghost'} leftIcon={<DownArrowIcon />} onClick={() => {
                                            let optionClone = [...optionsState!];
                                            const row = optionClone.splice(i, 1)[0];
                                            optionClone.splice(i + 1, 0, row);
                                            updateState(optionClone);
                                        }}></Button>
                                    </Td>
                                    <Td>
                                        <Input value={opt?.text ?? ''} onChange={e => {
                                            let optionClone = [...optionsState!];
                                            optionClone[i].text = e.currentTarget.value;
                                            updateState(optionClone);
                                        }} />
                                    </Td>
                                    <Td>
                                        <Select
                                            value={opt.destinationPageContentId}
                                            placeholder='Select a destination...'
                                            onChange={e => {
                                                let optionClone = [...optionsState!];
                                                optionClone[i].destinationPageContentId = e.currentTarget.value;
                                                updateState(optionClone);
                                            }}>
                                            {pageSummaries?.map((pageSummary, pageI) => {
                                                return <option key={pageI} value={pageSummary.id}>{pageSummary.id}</option>
                                            })}
                                        </Select>
                                    </Td>
                                    <Td><Tooltip label="Opens the specified page.">
                                        <LinkBox>
                                            <LinkOverlay href={`/page/${opt?.destinationPageContentId}`}>
                                                <Button isDisabled={!opt?.destinationPageContentId}>
                                                    View
                                                </Button>
                                            </LinkOverlay>
                                        </LinkBox>
                                    </Tooltip></Td>
                                    <Td>
                                        <Button rightIcon={<SettingsIcon />} onClick={() => {
                                            setOptionIndex(i);
                                            onOpen();
                                        }}>Settings</Button>
                                    </Td>
                                    <Td><Tooltip label="Deletes this option. The destination page is unaffected.">
                                        <Button colorScheme='red' rightIcon={<DeleteIcon />} onClick={() => {
                                            let optionClone = [...optionsState!];
                                            optionClone.splice(i, 1);
                                            updateState(optionClone);
                                        }}>
                                            Delete
                                        </Button></Tooltip></Td>
                                </Tr>
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Button onClick={_ => {
                    let optionClone = [...optionsState!];
                    optionClone.push({});
                    updateState(optionClone);
                }}>Add Option</Button>

                {/* Adv settings modal */}
                <Modal onClose={onClose} size='2xl' isOpen={isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Advanced Settings</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Heading size='sm' textTransform='uppercase'>
                                Script
                            </Heading>
                            <Text>Buttons, when pressed, can execute a script to set user variables and perform logic, such as variable arithmetic and control flow.</Text>
                            <Textarea fontFamily='monospace'
                                h='lg'
                                value={optionsState[optionIndex]?.script ?? ''}
                                onChange={e => {
                                    let optionClone = [...optionsState!];
                                    optionClone[optionIndex].script = e.currentTarget.value;
                                    updateState(optionClone);
                                }} />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
            :
            <Box padding={2} width='100%'>
                <VStack width='100%'>
                    {optionsState?.filter(opt => opt.destinationPageContentId && opt.text).map((opt, i) => {
                        return <Button key={i}
                            maxWidth='lg'
                            width='80%'
                            colorScheme={theme?.colourScheme}
                            whiteSpace="normal" height="auto" blockSize="auto"
                            isLoading={isButtonSpinning}
                            onClick={_ =>
                                executeOption(pageId!, opt.id!)}>
                            <Text padding={2}>{opt.text}</Text></Button>
                    })}
                </VStack>
            </Box>
        }
    </>);
};

export default ButtonOptions;