import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Text, Input, Box, AspectRatio, FormControl, FormHelperText, useEditableControls, ButtonGroup, IconButton, Editable, EditableInput, EditablePreview, Heading } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { CampaignTheme } from "../../models/campaignTheme";
import { PageElement } from "../../models/pageElement";

interface YoutubeProps {
    editMode: boolean;
    state?: PageElement;
    theme?: CampaignTheme;
    onUpdate?: (updatedState: PageElement) => void;
}

const Youtube: FC<YoutubeProps> = ({ editMode, state, onUpdate }) => {

    const [textState, setTextState] = React.useState<string>(state?.resources?.[0]?.url ?? '');
    const [youtubeEmbed, setYoutubeEmbed] = React.useState<string>(state?.resources?.[0]?.url ?? '');

    const extractToken = (link: string) => {
        return link
            .replace("https://www.youtube.com/watch?v=", "")
            .replace("https://youtu.be/", "")
            .replace("https://youtube.com/embed/", "");
    }

    const buildEmbedLink = (token: string) => {
        return `https://youtube.com/embed/${token}`;
    }
    const buildYoutubeLink = (token: string) => {
        return `https://www.youtube.com/watch?v=${token}`;
    }

    const updateState = (link: string) => {

        const ytToken = extractToken(link);
        const embedLink = buildEmbedLink(ytToken);

        setYoutubeEmbed(embedLink);
        onUpdate?.({ type: "youtube", resources: [{ url: embedLink }] });
    }

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls()

        return isEditing ? (
            <ButtonGroup justifyContent='center' size='sm'>
                <IconButton aria-label='submit' icon={<CheckIcon />} {...getSubmitButtonProps()} />
                <IconButton aria-label='close' icon={<CloseIcon />} {...getCancelButtonProps()} />
            </ButtonGroup>
        ) : (
            <IconButton aria-label='edit' size='xs' verticalAlign='super' margin={1} icon={<EditIcon />} {...getEditButtonProps()} />
        )
    }

    return (<Box w='100%'>
        {editMode ?
            <Box margin={3}>
                <FormControl>
                    <Heading size='sm'>Youtube Embed Link</Heading>
                    <Editable
                        value={textState}
                        isPreviewFocusable={false}
                        onChange={setTextState}
                        onSubmit={e => updateState(e)}
                        onCancel={_ => setTextState(youtubeEmbed)}
                    >
                        <Text as={EditablePreview}>{textState}</Text>
                        <Input as={EditableInput} />
                        <EditableControls />
                    </Editable>

                    <FormHelperText>Add the link of the Youtube video to play. It can be the full web address, the share link, or just the token.
                        Videos can be from any channel, but they must be public or unlisted. Private videos cannot be played.</FormHelperText>
                </FormControl>
            </Box>
            : <></>
        }
        {youtubeEmbed ?
            <>
                {editMode ?
                    <Text>
                        Embedding video at <a href={buildYoutubeLink(extractToken(youtubeEmbed))}>{buildYoutubeLink(extractToken(youtubeEmbed))}</a>.
                    </Text>
                    : <></>
                }
                <AspectRatio w='100%' ratio={16 / 9}>
                    <iframe
                        title='Youtube embedded video'
                        src={youtubeEmbed}
                        allowFullScreen
                    />
                </AspectRatio>
            </>
            : <></>
        }

    </Box>);

};

export default Youtube;