import { FC } from "react";
import { HiArrowUp } from '@react-icons/all-files/hi/HiArrowUp';

const UpArrowIcon: FC = () => {

    return (
        <HiArrowUp />
    )
}

export default UpArrowIcon;
