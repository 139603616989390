import { Heading, InputGroup, InputLeftAddon, Input } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { CampaignTheme } from "../../models/campaignTheme";
import { PageElement } from "../../models/pageElement";

interface TitleProps {
    editMode: boolean;
    state?: PageElement;
    theme?: CampaignTheme;
    onUpdate?: (updatedState: PageElement) => void;
}

const Title: FC<TitleProps> = ({ editMode, state, onUpdate }) => {

    const [titleText, setTitleText] = React.useState<string>(state?.text ?? "");

    const updateState = (title: string) => {
        setTitleText(title);
        onUpdate?.({ type: "title", text: title });
    }

    return (<>
        {editMode ?
            <InputGroup>
                <InputLeftAddon children='Title' />
                <Input aria-label="title" value={titleText} onChange={e => { updateState(e.target.value); }} />
            </InputGroup>
            :
            <Heading as='h1' size='2xl'>{titleText}</Heading>
        }
    </>);
};

export default Title;