import { AddIcon } from "@chakra-ui/icons";
import { useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, FormControl, FormHelperText, FormLabel, Box } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Constants } from "../constants";
import useFetchWithMsal from "../hooks/useFetchWithMsal";

const CreateCampaign: FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [nameState, setNameState] = React.useState<string>("");
    const navigate = useNavigate();

    const { jsonAuthFetch } = useFetchWithMsal();

    const createCampaign = () => {
        jsonAuthFetch(`${Constants.url}/create-campaign`, { id: nameState })
            .then(data => {
                navigate(`/campaign/${data.id}/edit`);
            });
    };

    return (
        <Box>
            <Button colorScheme='blue' rightIcon={<AddIcon />} onClick={onOpen}>Create Campaign</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Campaign</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={nameState} onChange={e => setNameState(e.target.value)} onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    createCampaign();
                                }
                            }} />
                            <FormHelperText>Name of the campaign. This cannot be changed later.</FormHelperText>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={_e => createCampaign()} >
                            Create
                        </Button>
                        <Button variant='ghost' onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default CreateCampaign;
