import { ChakraProvider, Container } from '@chakra-ui/react';
import './App.css';
import {
  Route,
  Routes,
  useParams
} from "react-router-dom";
import About from './components/about';
import Home from './components/home';
import CampaignPage from './components/campaignPage';
import CampaignStartPage from './components/campaignStartPage';
import ContentPage from './components/contentPage';
import PrivacyBanner from './components/privacyBanner';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import LoginPage from './components/loginPage';

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  function CampaignEditRoute() {
    let { campaignId } = useParams();
    return (<CampaignPage campaignId={campaignId!} />);
  }

  function CampaignRoute() {
    let { campaignId } = useParams();
    return (<CampaignStartPage campaignId={campaignId!} />);
  }

  function ContentPageView() {
    let { campaignId, pageId } = useParams();
    return (<ContentPage pageId={pageId!} campaignId={campaignId!} editMode={false} />);
  }

  function ContentPageEdit() {
    let { campaignId, pageId } = useParams();
    return (<ContentPage pageId={pageId!} campaignId={campaignId!} editMode={true} />);
  }

  return (
    <ChakraProvider>
      <MsalProvider instance={pca!}>

        <PrivacyBanner />

        <Container maxW='4xl' centerContent>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="campaign/:campaignId/edit" element={<CampaignEditRoute />} />
            <Route path="campaign/:campaignId" element={<CampaignRoute />} />
            <Route path="about" element={<About />} />
            <Route path="page/:campaignId/:pageId/edit" element={<ContentPageEdit />} />
            <Route path="page/:campaignId/:pageId" element={<ContentPageView />} />
            <Route path="login" element={<LoginPage />} />
          </Routes>

        </Container>
      </MsalProvider>
    </ChakraProvider>
  );

}

export default App;
