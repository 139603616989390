import { Alert, AlertIcon, Box, Center, Spinner } from '@chakra-ui/react';
import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Constants } from '../constants';
import useFetchWithMsal from '../hooks/useFetchWithMsal';

interface CampaignProps {
    campaignId: string;
}

/**
 * Redirects to the given campaigns start page. Shows a warning if one is not configured.
 */
const CampaignStartPage: FC<CampaignProps> = ({ campaignId }) => {

    const [isLoadedState, setIsLoadedState] = React.useState<boolean>(false);
    const [hasErrorState, setHasErrorState] = React.useState<boolean>(false);

    const { jsonFetch } = useFetchWithMsal();

    const navigate = useNavigate();

    const fetchCampaignData = useCallback((campaignId: string) => {
        jsonFetch(`${Constants.url}/campaign/${campaignId}`)
            .then(data => {
                setIsLoadedState(true);

                if (data?.startPageId) {
                    navigate(`/page/${campaignId}/${data.startPageId}`, { replace: true });
                }
                else {
                    setHasErrorState(true);
                }
            });
    }, [jsonFetch, navigate]);

    useEffect(() => {
        fetchCampaignData(campaignId);
    }, [campaignId, fetchCampaignData]);

    return (
        <Box width="100%" padding={2}>

            <Center>
                <Spinner
                    hidden={isLoadedState}
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    size='xl'
                />
                {hasErrorState ?
                    <Alert status='error'>
                        <AlertIcon />
                        Could not find a start page. Check the campaign has a start page.
                    </Alert>
                    : <></>
                }
            </Center>
        </Box>
    );
};

export default CampaignStartPage;
