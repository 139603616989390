import { Box, Heading, Link, Text, Textarea } from "@chakra-ui/react";
import React from "react";
import { FC } from "react";
import { CampaignTheme } from "../../models/campaignTheme";
import { PageElement } from "../../models/pageElement";
import ReactMarkdown from "react-markdown";

interface PageTextProps {
    editMode: boolean;
    state?: any;
    theme?: CampaignTheme;
    onUpdate?: (updatedState: PageElement) => void;
}

const PageText: FC<PageTextProps> = ({ editMode, state, onUpdate }) => {

    const [pageText, setPageText] = React.useState<string>(state?.text ?? "");

    const updateState = (pageText: string) => {
        setPageText(pageText);
        onUpdate?.({ type: "text", text: pageText });
    }

    return (<>
        {editMode ?
            <>
                <Text>
                    Text below will be presented on the page. The page supports <Link href="https://en.wikipedia.org/wiki/Markdown" color='blue.500'>Markdown</Link>,
                    a basic markup language for inserting headers, links, and text formatting. See the <Link href="https://commonmark.org/help/" target="_blank" color='blue.500'>Markdown reference</Link> for hints.
                </Text>
                <Textarea minH="md" value={pageText ?? ''} onChange={e => { updateState(e.target.value); }} />
            </>
            :
            <Box whiteSpace='pre-wrap'>
                <ReactMarkdown
                    components={{
                        h1: ({ node, ...props }) => <Heading as='h1' size='2xl'{...props} />,
                        h2: ({ node, ...props }) => <Heading as='h2' size='xl'{...props} />,
                        h3: ({ node, ...props }) => <Heading as='h3' size='lg'{...props} />,
                        h4: ({ node, ...props }) => <Heading as='h4' size='md'{...props} />,
                        h5: ({ node, ...props }) => <Heading as='h5' size='sm'{...props} />,
                        h6: ({ node, ...props }) => <Heading as='h6' size='xs'{...props} />,
                        a: ({ node, ...props }) => <Link color='blue.500' {...props} />,
                    }}>
                    {pageText}
                </ReactMarkdown>
            </Box>
        }
    </>);
};

export default PageText;
